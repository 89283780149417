<template>
  <div id="data-list-list-view" class="data-list-container">
    <h1>{{ $t("securityEvaluations") }}</h1>
    <br>
    <div class="flex flex-wrap items-center justify-between">
      <div class="flex items-center">
<!--       <vs-button  @click="moov()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
 -->   </div>
      <div class="flex items-center">
        <vs-button v-if="action_button === 1" @click="missionStep()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
        <vs-button v-if="action_button === 2" @click="validationStep()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
      </div>
      </div>

  
    <div  v-if="activePrompt1"  class="clearfix">
    <div  class="vx-row">

      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
      <p>{{ $t("mission") }}<b style="color: #ff6141">*</b></p>
          <vs-select
              :closeOnSelect="false"
              v-model="mission"
              data-vv-validate-on="blur"
              autocomplete
              name="mission"
              class="w-full"
          >
          <vs-select-item :key="tem" :value="tem.id" :text="tem.poNumber" v-for="tem in missions" /></vs-select> 
      </div>
      
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
          <p>{{ $t("timing") }}<b style="color: #ff6141">*</b></p>
          <vs-select
              :closeOnSelect="false"
              v-model="timing"
              data-vv-validate-on="blur"
              autocomplete
              name="timing"
              class="w-full"
          >
          <vs-select-item :key="tem1" :value="tem1.key" :text="tem1.name" v-for="tem1 in timings" /></vs-select> 
      </div>

      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
      <p>{{ $t("siteGroup") }}<b style="color: #ff6141">*</b></p>
      <vs-select
      v-if="getlang ==='fr'" 
      :disabled ="siteGroup_change"
      :closeOnSelect="false"
      v-model="siteGroup"
      data-vv-validate-on="blur"
      autocomplete
      name="siteGroup"
      class="w-full"
      >
      <vs-select-item v-if="getlang ==='fr'" :key="tem2" :value="tem2.key" :text="tem2.name" v-for="tem2 in typeEvaluation" /></vs-select> 
      
      <vs-select
      v-if="getlang ==='en'"
      :disabled ="siteGroup_change"
      v-validate="'required'"
      :closeOnSelect="false"
      v-model="siteGroup"
      data-vv-validate-on="blur"
      autocomplete
      name="siteGroup"
      class="w-full"
      >
      <vs-select-item v-if="getlang ==='en'" :key="tem2" :value="tem2.key" :text="tem2.name" v-for="tem2 in typeEvaluation_en" /></vs-select> 
    
    </div>

      <div v-if="siteGroup === false " class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
        <p v-if="siteGroup === false ">{{ $t("site") }}<b style="color: #ff6141">*</b></p>
        <vs-select
        v-if="siteGroup === false "
        :closeOnSelect="false"
        v-model="site"
        data-vv-validate-on="blur"
        autocomplete
        name="site"
        class="w-full"
        >
        <vs-select-item :key="tem3" :value="tem3" :text="tem3.name" v-for="tem3 in sites" /></vs-select> 

      </div>
    </div>
    <div  class="vx-row  mt-4">
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4"> 
        <vs-checkbox  v-model="feasibility">
            {{$t("feasibility")}}
        </vs-checkbox>
      </div>
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4"> 
        <vs-checkbox  v-model="achievableInMoreFavorablePeriod">
            {{$t("achievableInMoreFavorablePeriod")}}
          </vs-checkbox>
      </div>
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4"> 
        <vs-checkbox  v-model="notFeasibleOverLongPeriod">
            {{$t("notFeasibleOverLongPeriod")}}
        </vs-checkbox>
      </div>
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4"> 
        <vs-checkbox  v-model="initializeCmt">
            {{$t("initializeCmt")}}
        </vs-checkbox>
      </div>
    </div>
    <div class="vx-col sm:w-full md:w-full lg:w-full xs:w-full mt-4">
      <p>{{ $t("comment") }}<b style="color: #ff6141" >*</b> </p>
      <vs-textarea
      v-validate="'required'"
      data-vv-validate-on="blur"
      name="comment"
      v-model="comment"
      rows="5"
      class="w-full" />
      <span class="text-danger text-sm" v-show="errors.has('comment')">{{$t("comment_error")}}</span>
    </div>
    <vx-card  class="mt-10" no-shadow card-border>
            <div class="vx-row ">
            <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">EVALUATION</span>
            </div>
            <vs-divider />
            </div>
            </div>

            <div class="vx-row ">
                  <vs-table stripe class="w-full  " :data="questions">
                  <template slot="thead">
                    <vs-th>N°</vs-th>
                    <vs-th sort-key="Libelle">{{ $t("Libelle") }}</vs-th>
                    <vs-th sort-key="eva_instable">{{ $t("eva_instable") }}</vs-th>
                    <vs-th sort-key="eva_vunerable">{{ $t("eva_vunerable") }}</vs-th>
                    <vs-th sort-key="eva_consequance">{{ $t("eva_consequance") }}</vs-th>
                    <vs-th sort-key="eva_probabilite">{{ $t("eva_probabilite") }}</vs-th> 
                    <!-- <vs-th>SCORE GLOBAL</vs-th>
                    <vs-th>RÉSULTAT FINAL</vs-th> -->
                  </template>

                  <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    
                    
                      <vs-td>{{indextr+1}}</vs-td>
                        
                      <vs-td >{{tr.name}}</vs-td>

                    <vs-td >
                      <vs-select @input="instability_tr(tr,indextr)" v-model="tr.instability" 
                      autocomplete class="w-full">
                      <vs-select-item :key="item1" :value="item1.key" :text="item1.name" v-for="item1 in evaluations" /></vs-select>
                    </vs-td> 

                    <vs-td >
                      <vs-select @input="vulnerability_tr(tr,indextr)" v-model="tr.vulnerability" 
                      autocomplete class="w-full">
                      <vs-select-item :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in evaluations" /></vs-select>
                    </vs-td>

                    <vs-td >
                      <vs-select @input="consequence_tr(tr,indextr)" v-model="tr.consequence" 
                      autocomplete class="w-full">
                      <vs-select-item :key="item3" :value="item3.key" :text="item3.name" v-for="item3 in evaluations" /></vs-select>
                    </vs-td>

                    <vs-td >
                      <vs-select
                      @input= "probability_tr(tr,indextr)" v-model="tr.probability " 
                      autocomplete class="w-full">
                      <vs-select-item :key="item4" :value="item4.key" :text="item4.name" v-for="item4 in probabilities" /></vs-select>
                    </vs-td>
                    
                    </vs-tr>
                  </template>
                  </vs-table>
                  <div>
                   <vs-button color="primary"  class="mt-4" @click="submit_produit()" >{{ $t("Ajouter") }}</vs-button>
                  </div>
            </div>

    </vx-card>
    <vx-card  class="mt-10" no-shadow card-border>
      <div class="vx-row ">
      <div class="vx-col w-full">
        <div class="flex items-end px-3">
          <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
          <span class="font-medium text-lg leading-none">{{ $t("site_evalue") }}</span>
        </div>
        <vs-divider />
      </div>
      </div>
      <div class="vx-row ">
      </div>
      <div class="vx-row ">
      </div>
        <div class="vx-row ">
            <div v-if="siteGroup === true && produits_sites.length">
              <vs-button color="danger"  class="mt-4" @click="deletedAll()" >{{ $t("all_delete") }}</vs-button>
            </div>
            <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_sites">
            <template slot="header">
            </template>
            <template slot="thead">
            <vs-th hidden  >N°</vs-th>
            <vs-th>Site(s)</vs-th>
            <vs-th v-if="siteGroup === false ">Actions</vs-th>
            </template>

            <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

              <vs-td hidden :data="data[indextr].id">
                {{data[indextr].id}}
              </vs-td>
              <vs-td :data="data[indextr].site_obj">
                {{data[indextr].site_obj.name}}
              </vs-td>           
              <vs-td v-if="siteGroup === false " class="whitespace-no-wrap">
                <div class="flex">                 
                  <vs-button  @click.stop="deleteProduit(data[indextr].site)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
            </template>
            </vs-table>
        </div>
    </vx-card>
    <vs-button color="success"  class="float-right mt-4" @click="validate_step()" >{{ $t("Valider") }}</vs-button>
    </div>

   
    <div  v-if="activePrompt2"  class="clearfix">
    <div  class="vx-row">

      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
      <p>{{ $t("mission") }}<b style="color: #ff6141"></b></p>
          <vs-select
              disabled
              :closeOnSelect="false"
              v-model="mission"
              data-vv-validate-on="blur"
              autocomplete
              name="mission"
              class="w-full"
          >
          <vs-select-item :key="tem" :value="tem.id" :text="tem.poNumber" v-for="tem in missions" /></vs-select> 

      </div>
      
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
          <p>{{ $t("timing") }}<b style="color: #ff6141"></b></p>
          <vs-select
              disabled
              :closeOnSelect="false"
              v-model="timing"
              data-vv-validate-on="blur"
              autocomplete
              name="timing"
              class="w-full"
          >
          <vs-select-item :key="tem1" :value="tem1.key" :text="tem1.name" v-for="tem1 in timings" /></vs-select> 

      </div>

      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
      <p>{{ $t("siteGroup") }}<b style="color: #ff6141"></b></p>
<!--         :disabled ="siteGroup_change || siteGroup"-->    
      <vs-select
      v-if="getlang ==='fr'"
      disabled
      v-validate="'required'"
      :closeOnSelect="false"
      v-model="siteGroup"
      data-vv-validate-on="blur"
      autocomplete
      name="siteGroup"
      class="w-full"
      >
      <vs-select-item v-if="getlang ==='fr'" :key="tem2" :value="tem2.key" :text="tem2.name" v-for="tem2 in typeEvaluation" /></vs-select> 
      <vs-select
      v-if="getlang ==='en'"
      :disabled ="siteGroup_change"
      v-validate="'required'"
      :closeOnSelect="false"
      v-model="siteGroup"
      data-vv-validate-on="blur"
      autocomplete
      name="siteGroup"
      class="w-full"
      >
      <vs-select-item v-if="getlang ==='en'" :key="tem2" :value="tem2.key" :text="tem2.name" v-for="tem2 in typeEvaluation_en" /></vs-select> 
    
    </div>

      <div v-if="siteGroup === false " class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
        <p v-if="siteGroup === false ">{{ $t("site") }}<b style="color: #ff6141"></b></p>
        <vs-select
        v-if="siteGroup === false "
        v-validate="'required'"
        :closeOnSelect="false"
        v-model="site"
        data-vv-validate-on="blur"
        autocomplete
        name="site"
        class="w-full"
        >
        <vs-select-item :key="tem3" :value="tem3" :text="tem3.name" v-for="tem3 in sites" /></vs-select> 
        <span class="text-danger text-sm"><b style="color: #ff6141">Séléctionnez un site pour les détails</b></span>
      </div>
    </div>
    <div  class="vx-row  mt-4">
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4"> 
        <vs-checkbox disabled v-model="feasibility">
            {{$t("feasibility")}}
        </vs-checkbox>
      </div>
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4"> 
        <vs-checkbox disabled  v-model="achievableInMoreFavorablePeriod">
            {{$t("achievableInMoreFavorablePeriod")}}
          </vs-checkbox>
      </div>
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4"> 
        <vs-checkbox disabled  v-model="notFeasibleOverLongPeriod">
            {{$t("notFeasibleOverLongPeriod")}}
        </vs-checkbox>
      </div>
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4"> 
        <vs-checkbox disabled v-model="initializeCmt">
            {{$t("initializeCmt")}}
        </vs-checkbox>
      </div>
    </div>
    <div class="vx-col sm:w-full md:w-full lg:w-full xs:w-full mt-4">
      <p>{{ $t("comment") }}<b style="color: #ff6141" ></b> </p>
      <vs-textarea
      disabled
      data-vv-validate-on="blur"
      name="comment"
      v-model="comment"
      rows="5"
      class="w-full" />
    </div>
    <vx-card  class="mt-10" no-shadow card-border>
            <div class="vx-row ">
            <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">EVALUATION</span>
            </div>
            <vs-divider />
            </div>
            </div>

            <div class="vx-row ">
                  <vs-table stripe class="w-full  " :data="questions">
                  <template slot="thead">
                    <vs-th>N°</vs-th>
                    <vs-th sort-key="Libelle">{{ $t("Libelle") }}</vs-th>
                    <vs-th sort-key="eva_instable">{{ $t("eva_instable") }}</vs-th>
                    <vs-th sort-key="eva_vunerable">{{ $t("eva_vunerable") }}</vs-th>
                    <vs-th sort-key="eva_consequance">{{ $t("eva_consequance") }}</vs-th>
                    <vs-th sort-key="eva_probabilite">{{ $t("eva_probabilite") }}</vs-th> 
                    <vs-th sort-key="score">{{ $t("score") }}</vs-th> 
                    <vs-th sort-key="final_result">{{ $t("final_result") }}</vs-th> 

                    <!-- <vs-th>SCORE GLOBAL</vs-th>
                    <vs-th>RÉSULTAT FINAL</vs-th> -->
                  </template>

                  <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    
                    
                      <vs-td>{{indextr+1}}</vs-td>
                        
                      <vs-td >{{tr.name}}</vs-td>

                    <vs-td >
                      <vs-select disabled @input="instability_tr(tr,indextr)" v-model="tr.instability" 
                      autocomplete class="w-full">
                      <vs-select-item :key="item1" :value="item1.key" :text="item1.name" v-for="item1 in evaluations" /></vs-select>
                    </vs-td> 

                    <vs-td >
                      <vs-select disabled @input="vulnerability_tr(tr,indextr)" v-model="tr.vulnerability" 
                      autocomplete class="w-full">
                      <vs-select-item :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in evaluations" /></vs-select>
                    </vs-td>

                    <vs-td >
                      <vs-select  disabled @input="consequence_tr(tr,indextr)" v-model="tr.consequence" 
                      autocomplete class="w-full">
                      <vs-select-item :key="item3" :value="item3.key" :text="item3.name" v-for="item3 in evaluations" /></vs-select>
                    </vs-td>

                    <vs-td >
                      <vs-select
                      disabled
                      @input= "probability_tr(tr,indextr)" v-model="tr.probability " 
                      autocomplete class="w-full">
                      <vs-select-item :key="item4" :value="item4.key" :text="item4.name" v-for="item4 in probabilities" /></vs-select>
                    </vs-td>

<!--                     <vs-td >{{tr.score}}</vs-td>
 -->
                    <vs-td >{{evaluationsObject[tr.final_result]}}</vs-td>

                    <vs-td >
                      <p>
                        <vs-chip  :percent="100" :color="getOrderStatusColor1(tr.final_result)"></vs-chip>
                      </p>
                      <!-- <p>
                        <br>
                        {{tr.final_result}}
                      </p> -->
                    </vs-td>

                    </vs-tr>
                  </template>
                  </vs-table>
                <!--   <div>
                   <vs-button color="primary"  class="mt-4" @click="submit_produit()" >Ajouter</vs-button>
                  </div> -->
            </div>

    </vx-card>
    <!-- <vx-card  class="mt-10" no-shadow card-border>
      <div class="vx-row ">
      <div class="vx-col w-full">
        <div class="flex items-end px-3">
          <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
          <span class="font-medium text-lg leading-none">Site(s) évalué(s)</span>
        </div>
        <vs-divider />
      </div>
      </div>
      <div class="vx-row ">
      </div>
      <div class="vx-row ">
      </div>
        <div class="vx-row ">
            <div v-if="siteGroup === true && produits_sites.length">
              <vs-button color="danger"  class="mt-4" @click="deletedAll()" >{{ $t("all_delete") }}</vs-button>
            </div>
            <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_sites">
            <template slot="header">
            </template>
            <template slot="thead">
            <vs-th hidden  >N°</vs-th>
            <vs-th>Site(s)</vs-th>
            <vs-th v-if="siteGroup === false ">Actions</vs-th>
            </template>

            <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

              <vs-td hidden :data="data[indextr].id">
                {{data[indextr].id}}
              </vs-td>
              <vs-td :data="data[indextr].site_obj">
                {{data[indextr].site_obj.name}}
              </vs-td>           
              <vs-td v-if="siteGroup === false " class="whitespace-no-wrap">
                <div class="flex">                 
                  <vs-button  @click.stop="deleteProduit(data[indextr].site)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
            </template>
            </vs-table>
        </div>
    </vx-card> -->
    <vs-button color="success"  class="float-right mt-4" @click="quit()" >{{ $t("Quit") }}</vs-button>
    </div>

   
    <div  v-if="activePrompt3"  class="clearfix">
    <div  class="vx-row">

      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
      <p>{{ $t("mission") }}<b style="color: #ff6141"></b></p>
          <vs-select
              :closeOnSelect="false"
              v-model="mission"
              data-vv-validate-on="blur"
              autocomplete
              name="mission"
              class="w-full"
          >
          <vs-select-item :key="tem" :value="tem.id" :text="tem.poNumber" v-for="tem in missions" /></vs-select> 

      </div>
      
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
          <p>{{ $t("timing") }}<b style="color: #ff6141"></b></p>
          <vs-select
              :closeOnSelect="false"
              v-model="timing"
              data-vv-validate-on="blur"
              autocomplete
              name="timing"
              class="w-full"
          >
          <vs-select-item :key="tem1" :value="tem1.key" :text="tem1.name" v-for="tem1 in timings" /></vs-select> 

      </div>

      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
      <p>{{ $t("siteGroup") }}<b style="color: #ff6141"></b></p>
<!--         :disabled ="siteGroup_change || siteGroup"-->    
      <vs-select
      v-if="getlang ==='fr'"
      :disabled ="siteGroup_change"
      v-validate="'required'"
      :closeOnSelect="false"
      v-model="siteGroup"
      data-vv-validate-on="blur"
      autocomplete
      name="siteGroup"
      class="w-full"
      >
      <vs-select-item v-if="getlang ==='fr'" :key="tem2" :value="tem2.key" :text="tem2.name" v-for="tem2 in typeEvaluation" /></vs-select> 
      
      <vs-select
      v-if="getlang ==='en'"
      :disabled ="siteGroup_change"
      v-validate="'required'"
      :closeOnSelect="false"
      v-model="siteGroup"
      data-vv-validate-on="blur"
      autocomplete
      name="siteGroup"
      class="w-full"
      >
      <vs-select-item v-if="getlang ==='en'" :key="tem2" :value="tem2.key" :text="tem2.name" v-for="tem2 in typeEvaluation_en" /></vs-select> 
      
      </div>

      <div v-if="siteGroup === false " class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4">
        <p v-if="siteGroup === false ">{{ $t("site") }}<b style="color: #ff6141"></b></p>
        <vs-select
        v-if="siteGroup === false "
        v-validate="'required'"
        :closeOnSelect="false"
        v-model="site"
        data-vv-validate-on="blur"
        autocomplete
        name="site"
        class="w-full"
        >
        <vs-select-item :key="tem3" :value="tem3" :text="tem3.name" v-for="tem3 in sites" /></vs-select> 
        <span class="text-danger text-sm"><b style="color: #ff6141">Séléctionnez un site pour les modifications</b></span>
      </div>
    </div>
    <div  class="vx-row  mt-4">
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4"> 
        <vs-checkbox  v-model="feasibility">
            {{$t("feasibility")}}
        </vs-checkbox>
      </div>
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4"> 
        <vs-checkbox   v-model="achievableInMoreFavorablePeriod">
            {{$t("achievableInMoreFavorablePeriod")}}
          </vs-checkbox>
      </div>
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4"> 
        <vs-checkbox   v-model="notFeasibleOverLongPeriod">
            {{$t("notFeasibleOverLongPeriod")}}
        </vs-checkbox>
      </div>
      <div class="vx-col sm:w-1/4 md:w-1/4 lg:1/4 xs:w-1/4"> 
        <vs-checkbox  v-model="initializeCmt">
            {{$t("initializeCmt")}}
        </vs-checkbox>
      </div>
    </div>
    <div class="vx-col sm:w-full md:w-full lg:w-full xs:w-full mt-4">
      <p>{{ $t("comment") }}<b style="color: #ff6141" ></b> </p>
      <vs-textarea
      data-vv-validate-on="blur"
      name="comment"
      v-model="comment"
      rows="5"
      class="w-full" />
    </div>
    <vx-card  class="mt-10" no-shadow card-border>
            <div class="vx-row ">
            <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">EVALUATION</span>
            </div>
            <vs-divider />
            </div>
            </div>

            <div class="vx-row ">
                  <vs-table stripe class="w-full  " :data="questions">
                  <template slot="thead">
                    <vs-th>N°</vs-th>
                    <vs-th sort-key="Libelle">{{ $t("Libelle") }}</vs-th>
                    <vs-th sort-key="eva_instable">{{ $t("eva_instable") }}</vs-th>
                    <vs-th sort-key="eva_vunerable">{{ $t("eva_vunerable") }}</vs-th>
                    <vs-th sort-key="eva_consequance">{{ $t("eva_consequance") }}</vs-th>
                    <vs-th sort-key="eva_probabilite">{{ $t("eva_probabilite") }}</vs-th> 
                    <!-- <vs-th>SCORE GLOBAL</vs-th>
                    <vs-th>RÉSULTAT FINAL</vs-th> -->
                  </template>

                  <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                    
                    
                      <vs-td>{{indextr+1}}</vs-td>
                        
                      <vs-td >{{tr.name}}</vs-td>

                    <vs-td >
                      <vs-select v-if="getlang ==='fr'" @input="instability_tr(tr,indextr)" v-model="tr.instability" 
                      autocomplete class="w-full">
                      <vs-select-item v-if="getlang ==='fr'" :key="item1" :value="item1.key" :text="item1.name" v-for="item1 in evaluations" /></vs-select>
                      
                      <vs-select v-if="getlang ==='en'" @input="instability_tr(tr,indextr)" v-model="tr.instability" 
                      autocomplete class="w-full">
                      <vs-select-item v-if="getlang ==='en'" :key="item1" :value="item1.key" :text="item1.name" v-for="item1 in evaluations_en" /></vs-select>
                    </vs-td> 

                    <vs-td >
                      <vs-select v-if="getlang ==='fr'" @input="vulnerability_tr(tr,indextr)" v-model="tr.vulnerability" 
                      autocomplete class="w-full">
                      <vs-select-item v-if="getlang ==='fr'" :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in evaluations" /></vs-select>
                    
                      <vs-select v-if="getlang ==='en'" @input="vulnerability_tr(tr,indextr)" v-model="tr.vulnerability" 
                      autocomplete class="w-full">
                      <vs-select-item v-if="getlang ==='en'" :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in evaluations_en" /></vs-select>
                    
                    </vs-td>

                    <vs-td >
                      <vs-select v-if="getlang ==='fr'"  @input="consequence_tr(tr,indextr)" v-model="tr.consequence" 
                      autocomplete class="w-full">
                      <vs-select-item v-if="getlang ==='fr'" :key="item3" :value="item3.key" :text="item3.name" v-for="item3 in evaluations" /></vs-select>
                   
                      <vs-select v-if="getlang ==='en'"  @input="consequence_tr(tr,indextr)" v-model="tr.consequence" 
                      autocomplete class="w-full">
                      <vs-select-item v-if="getlang ==='en'" :key="item3" :value="item3.key" :text="item3.name" v-for="item3 in evaluations_en" /></vs-select>
                   
                    </vs-td>

                    <vs-td >
                      <vs-select
                      v-if="getlang ==='fr'"
                      @input= "probability_tr(tr,indextr)" v-model="tr.probability " 
                      autocomplete class="w-full">
                      <vs-select-item v-if="getlang ==='fr'" :key="item4" :value="item4.key" :text="item4.name" v-for="item4 in probabilities" /></vs-select>
                    
                      <vs-select
                      v-if="getlang ==='en'"
                      @input= "probability_tr(tr,indextr)" v-model="tr.probability " 
                      autocomplete class="w-full">
                      <vs-select-item v-if="getlang ==='en'" :key="item4" :value="item4.key" :text="item4.name" v-for="item4 in probabilities_en" /></vs-select>
                    
                    </vs-td>
                    
                    </vs-tr>
                  </template>
                  </vs-table>
                  <div>
                   <vs-button color="primary"  class="mt-4" @click="submit_produit()" >{{ $t("Ajouter") }}</vs-button>
                  </div>
            </div>

    </vx-card>
    <vx-card  class="mt-10" no-shadow card-border>
      <div class="vx-row ">
      <div class="vx-col w-full">
        <div class="flex items-end px-3">
          <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
          <span class="font-medium text-lg leading-none">{{ $t("site_evalue") }}</span>
        </div>
        <vs-divider />
      </div>
      </div>
      <div class="vx-row ">
      </div>
      <div class="vx-row ">
      </div>
        <div class="vx-row ">
            <div v-if="siteGroup === true && produits_sites.length">
              <vs-button color="danger"  class="mt-4" @click="deletedAll()" >{{ $t("all_delete") }}</vs-button>
            </div>
            <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_sites">
            <template slot="header">
            </template>
            <template slot="thead">
            <vs-th hidden  >N°</vs-th>
            <vs-th>Site(s)</vs-th>
            <vs-th v-if="siteGroup === false ">Actions</vs-th>
            </template>

            <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

              <vs-td hidden :data="data[indextr].id">
                {{data[indextr].id}}
              </vs-td>
              <vs-td :data="data[indextr].site_obj">
                {{data[indextr].site_obj.name}}
              </vs-td>           
              <vs-td v-if="siteGroup === false " class="whitespace-no-wrap">
                <div class="flex">                 
                  <vs-button  @click.stop="deleteProduit(data[indextr].site)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
            </template>
            </vs-table>
        </div>
    </vx-card>
    <vs-button color="success"  class="float-right mt-4" @click="validate_step()" >{{ $t("Valider") }}</vs-button>
    </div>
    
  </div>
</template>
<script>
  
export default {

  data () {
    return {
      action_button:'',
      missionControle_data:'',
      mission_name:'',
      mission_id: '',
      sites:'',
      missions:'',
      mission:'',
      site:'',
      siteGroup:false,
      comment:'',
      feasibility:false,
      timing:'immediate',
      achievableInMoreFavorablePeriod:false,
      notFeasibleOverLongPeriod:false,
      initializeCmt:false,
      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      produits_sites:[],
      evaluationsObject:{
        extreme:'EXTRÊME',
        considerable:'CONSIDÉRABLE',
        high:'ÉLEVÉ',
        moderate:'MODÉRÉ',
        negligible:'NÉGLIGEABLE'
      },
      evaluationsObject_en:{
        extreme:'EXTREME',
        considerable:'CONSIDERABLE',
        high:'HIGH',
        moderate:'MODERATE',
        negligible:'NEGLIGEABLE'
      },
      timings: [
        {
          key: 'immediate',
          name: 'Immédiate'
        },
        {
          key: 'reported',
          name: 'Signalée'
        }
      ],
      timings_en: [
        {
          key: 'immediate',
          name: 'Immediate'
        },
        {
          key: 'reported',
          name: 'Reported'
        }
      ],
      evaluations: [
        {
          key: 'extreme',
          name: 'EXTRÊME'
        },
        {
          key: 'considerable',
          name: 'CONSIDÉRABLE'
        },
        {
          key: 'high',
          name: 'ÉLEVÉ'
        },
        {
          key: 'moderate',
          name: 'MODÉRÉ'
        },
        {
          key: 'negligible',
          name: 'NÉGLIGEABLE'
        }
      ],
      evaluations_en: [
        {
          key: 'extreme',
          name: 'EXTREME'
        },
        {
          key: 'considerable',
          name: 'CONSIDERABLE'
        },
        {
          key: 'high',
          name: 'HIGH'
        },
        {
          key: 'moderate',
          name: 'MODERATE'
        },
        {
          key: 'negligible',
          name: 'NEGLIGIBLE'
        }
      ],
      probabilities: [
        {
          key: 'extreme70',
          name: 'EXTRÊME >70%'
        },
        {
          key: 'considerable50',
          name: 'CONSIDÉRABLE >50%'
        },
        {
          key: 'high25',
          name: 'ÉLEVÉ >25%'
        },
        {
          key: 'moderate10',
          name: 'MODÉRÉ >10%'
        },
        {
          key: 'negligible',
          name: 'NÉGLIGEABLE'
        }
      ],
      probabilities_en: [
        {
          key: 'extreme70',
          name: 'EXTREME >70%'
        },
        {
          key: 'considerable50',
          name: 'CONSIDERABLE >50%'
        },
        {
          key: 'high25',
          name: 'HIGH >25%'
        },
        {
          key: 'moderate10',
          name: 'MODERATE >10%'
        },
        {
          key: 'negligible',
          name: 'NEGLIGIBLE'
        }
      ],
      securityThreatEvaluations: [],
      questions:[],
      roleEvalution: {
        false: 'Site',
        true: 'Groupe'
      },
      roleEvaluation_en: {
        false: 'Site',
        true: 'Group'
      },
      typeEvaluation:[
        {
          key:true,
          name:'Goupe'
        },
        {
          key:false,
          name:'Site'
        }
      ],
      typeEvaluation_en:[
        {
          key:true,
          name:'Group'
        },
        {
          key:false,
          name:'Site'
        }
      ],
      selected: [],
      itemsPerPage: 20,
      isMounted: false,
      itemIndexProduit:-1,
      checkSite :false,
      obj_form : [],
      length_questions:'',
      update_chek : false,
      id:''
    }
  },

  watch:{
    mission (value) {
      if (value) {
        if (!this.siteGroup) {
          this.getAllsites(value)

        }
      }
    },
    siteGroup (value) {
      if (value === true) {
        this.site = ''
      } 
    },
    site (value) {
      if (this.update_chek === true) {
        if (value) {
          if (this.siteGroup === false) {
            const obj_rep_qest = this.obj_form
            this.questions = []
            for (let i = 0; i < obj_rep_qest.length; i++) {
              const element = obj_rep_qest[i]
              if (element.site === value.id) {
                this.feasibility = element.feasibility
                this.timing = element.timing
                this.comment = element.comment
                this.mission = element.mission
                this.achievableInMoreFavorablePeriod = element.achievableInMoreFavorablePeriod
                this.notFeasibleOverLongPeriod = element.notFeasibleOverLongPeriod
                this.initializeCmt = element.initializeCmt
                this.questions = element.questions
              }
            }
          }
        }
      }
    }
  },

  computed: {
    getlang () {
      return window.getPrendTaCom.$i18n.locale
    },
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.securityThreatEvaluations.length
    },

    siteGroup_change () {
      if (
        (
          this.produits_sites.length > 0
        )) return true
    }
  },
  methods: {
    missionStep () {
      this.$router.push('/Planifications').catch(() => {})
    },
    validationStep () {
      this.$router.push('/Validations').catch(() => {})
    },
    getOrderStatusColor1 (status) {
      if (status === 'negligible')   return '#00B050'
      if (status === 'moderate')   return '#92D050'
      if (status === 'high')   return '#FFFF00'
      if (status === 'considerable')   return '#FFC000'
      if (status === 'extreme')   return '#FF0000'
    
    },
    quit () {
      this.activePrompt1 = false
      this.activePrompt2 = false
      this.activePrompt3 = false
      this.initialise()
      if (this.action_button === 1) {
        this.missionStep()
      } else if (this.action_button === 2) {
        this.validationStep()
      }
    },
    editData (id, tr, comment, groupe, mission) {
      this.initialise()
      this.mission = mission
      this.id = id
      this.update_chek = true
      this.obj_form = []
      this.produits_sites = []
      this.questions = []
      this.questions_stable = []
   
      for (let i = 0; i < tr.length; i++) {
        this.questions_stable = []
        const obj = {
          site_obj : '',
          site : '',
          questions:'',
          feasibility:'',
          timing:'',
          comment:'',
          achievableInMoreFavorablePeriod:'',
          notFeasibleOverLongPeriod:'',
          initializeCmt:'',
          mission:''
        } 
        obj.mission = mission
        obj.comment = comment
        obj.site = tr[i].site.id
        obj.site_obj = tr[i].site
        obj.feasibility = tr[i].feasibility
        obj.timing = tr[i].timing
        obj.achievableInMoreFavorablePeriod = tr[i].achievableInMoreFavorablePeriod
        obj.notFeasibleOverLongPeriod = tr[i].notFeasibleOverLongPeriod
        obj.initializeCmt = tr[i].initializeCmt

        for (let j = 0; j < tr[i].SecurityHasQuestionHasSite.length; j++) {
          const element = tr[i].SecurityHasQuestionHasSite[j]
          const obj_question = {
            id:'',
            name:'',
            question:'',
            instability :'',
            vulnerability :'',
            consequence :'',
            probability : '',
            final_result:'',
            score:'',
            site_id:''
          }
          obj_question.site_id = tr[i].site.id
          obj_question.name = element.question.name
          obj_question.id = element.question.id
          obj_question.question = element.question
          obj_question.instability = element.instability_option
          obj_question.vulnerability = element.instability_option
          obj_question.consequence = element.consequence_option
          obj_question.probability = element.probability_option
          obj_question.final_result = element.final_result
          obj_question.score = element.score
          this.questions_stable.push(obj_question)
        }
        obj.questions = this.questions_stable
        this.obj_form.push(obj)
      }
      this.siteGroup = groupe
      if (groupe) {
        const object_response = this.obj_form[0]
        const questions_first = object_response.questions
        for (let k = 0; k < this.length_questions; k++) {
          this.questions.push(questions_first[k])
        }
        this.site = object_response.site.id
        this.site_obj = object_response.site
        this.feasibility = object_response.feasibility
        this.timing = object_response.timing
        this.comment = object_response.comment
        this.mission = object_response.mission
        this.achievableInMoreFavorablePeriod = object_response.achievableInMoreFavorablePeriod
        this.notFeasibleOverLongPeriod = object_response.notFeasibleOverLongPeriod
        this.initializeCmt = object_response.initializeCmt

      }
      this.activePrompt3 = true

    },
    ShowData (data) {
      this.initialise()
      const tr = data.securityHasQuestions
      const comment = data.comment
      const groupe = data.siteGroup
      const mission = data.mission.id
      this.mission = mission
      this.update_chek = true
      //this.obj_form = []
      this.produits_sites = []
      this.questions = []
      this.questions_stable = []
   
      for (let i = 0; i < tr.length; i++) {
        this.questions_stable = []
        const obj = {
          site_obj : '',
          site : '',
          questions:'',
          feasibility:'',
          timing:'',
          comment:'',
          achievableInMoreFavorablePeriod:'',
          notFeasibleOverLongPeriod:'',
          initializeCmt:'',
          mission:''
        } 
        obj.mission = mission
        obj.comment = comment
        obj.site = tr[i].site.id
        obj.site_obj = tr[i].site
        obj.feasibility = tr[i].feasibility
        obj.timing = tr[i].timing
        obj.achievableInMoreFavorablePeriod = tr[i].achievableInMoreFavorablePeriod
        obj.notFeasibleOverLongPeriod = tr[i].notFeasibleOverLongPeriod
        obj.initializeCmt = tr[i].initializeCmt

        for (let j = 0; j < tr[i].SecurityHasQuestionHasSite.length; j++) {
          const element = tr[i].SecurityHasQuestionHasSite[j]
          const obj_question = {
            id:'',
            name:'',
            question:'',
            instability :'',
            vulnerability :'',
            consequence :'',
            probability : '',
            final_result:'',
            score:'',
            site_id:''
          }
          obj_question.site_id = tr[i].site.id
          obj_question.name = element.question.name
          obj_question.id = element.question.id
          obj_question.question = element.question
          obj_question.instability = element.instability_option
          obj_question.vulnerability = element.instability_option
          obj_question.consequence = element.consequence_option
          obj_question.probability = element.probability_option
          obj_question.final_result = element.final_result
          obj_question.score = element.score
          this.questions_stable.push(obj_question)
        }
        obj.questions = this.questions_stable
        this.obj_form.push(obj)
      }
      this.siteGroup = groupe
      if (groupe) {
        const object_response = this.obj_form[0]
        const questions_first = object_response.questions
        for (let k = 0; k < questions_first.length; k++) {
          this.questions.push(questions_first[k])
        }
        this.site = object_response.site.id
        this.site_obj = object_response.site
        this.feasibility = object_response.feasibility
        this.timing = object_response.timing
        this.comment = object_response.comment
        this.mission = object_response.mission
        this.achievableInMoreFavorablePeriod = object_response.achievableInMoreFavorablePeriod
        this.notFeasibleOverLongPeriod = object_response.notFeasibleOverLongPeriod
        this.initializeCmt = object_response.initializeCmt
      }
      this.activePrompt2 = true

    },
    validate_step () {
    //this.$validator.validateAll().then(result => {
      if (this.produits_sites.length && this.mission !== '' && this.timing !== '' && this.comment !== '') {
        this.submitData()
      } else {
        this.$vs.notify({
          color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          text:this.$t('site_verification_liste'),
          position:'top-center'
        })
      }
    //})
    },
    async submitData () {
      this.$vs.loading()
      if (this.siteGroup === true) {
        this.cal = 1
      } else {
        this.cal = 0
      }
      const input = {
        mission : this.mission,
        siteGroup: this.cal,
        comment: this.comment,
        securityHasQuestions:this.produits_sites
      }
   
      let url = 'securityThreatEvaluations/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('securityEvalution_save')
      }
      if (this.id) {
        url += `${this.id}/`
        methods = 'put'
        message.success = this.$t('securityEvalution_update')
      }

      this.$http[methods](url, input)
        .then((response) => {
          this.getAllsecurityThreatEvaluations()
          window.getPrendTaCom.success(message.success, response)
          this.quit()
        })
        .catch((error) => {

          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''
            if (item === 'mission') {
              libelle = this.$t('mission')
            }
            if (item === 'siteGroup') {
              libelle = this.$t('siteGroup')
            }
            if (item === 'comment') {
              libelle = this.$t('comment')
            }
       
            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }

          window.getPrendTaCom.error(message.error)
        })
    },
    instability_tr (tr) {
      this.UPDATE_ITEM_PRODUIT(this.questions, tr)
    },
    vulnerability_tr (tr) {
    //tr.residualRisk = parseInt((tr.vulnerability) * parseInt(tr.residualRiskProbability))
      this.UPDATE_ITEM_PRODUIT(this.questions, tr)
    },
    consequence_tr (tr) {
    //tr.residualRisk = parseInt((tr.consequence) * parseInt(tr.residualRiskProbability))
      this.UPDATE_ITEM_PRODUIT(this.questions, tr)
    },
    probability_tr (tr) {
    //tr.residualRisk = parseInt((tr.probability) * parseInt(tr.residualRiskProbability))
      this.UPDATE_ITEM_PRODUIT(this.questions, tr)
    },
    UPDATE_ITEM_PRODUIT (produits, produit) {
      const itemIndex = produits.findIndex((p) => p.id === produit.id)
      Object.assign(produits[itemIndex], produit)
    },
    deletedAll () {
      this.produits_sites = []
      this.siteGroup = false
    },
    submit_produit () {
      if (this.siteGroup === false) {
        if (this.site !== '') {
          this.produit_submit = ''
          this.questions_options = [] 
          this.instability_options = []
          this.vulnerability_options = []
          this.consequence_options = []
          this.probability_options = []

          const obj = {
            site_obj : this.site,
            site : this.site.id,
            questions: [],
            instability_options:[],
            vulnerability_options:[],
            consequence_options:[],
            probability_options: [],
            feasibility:this.feasibility,
            timing:this.timing,
            achievableInMoreFavorablePeriod:this.achievableInMoreFavorablePeriod,
            notFeasibleOverLongPeriod:this.notFeasibleOverLongPeriod,
            initializeCmt:this.initializeCmt
          } 
          const produits_store = this.questions
          for (let i = 0; i < produits_store.length; i++) {
            this.questions_options.push(produits_store[i].id)
            this.instability_options.push(produits_store[i].instability)
            this.vulnerability_options.push(produits_store[i].vulnerability)
            this.consequence_options.push(produits_store[i].consequence)
            this.probability_options.push(produits_store[i].probability)
       
          }
          obj.questions = this.questions_options
          obj.instability_options = this.instability_options
          obj.vulnerability_options = this.vulnerability_options
          obj.consequence_options = this.consequence_options
          obj.probability_options = this.probability_options

          this.produit_submit = (obj)
          this.FIND_ITEM_PRODUIT(this.produits_sites, this.produit_submit)
          if (this.itemIndexProduit === -1) {
            this.ADD_ITEM_PRODUIT(this.produits_sites, this.produit_submit)
            this.$vs.notify({
              color:'success',
              icon:'done',
              text: this.$t('add_site_pro'),
              position:'top-right'
            })
            this.validateFormation_intialise()
          } else if (this.itemIndexProduit !== -1) {
            this.$vs.notify({
              color:'warning',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              time:15000,
              text: this.$t('add_site_pro_add'),
              position:'top-center'
            })
          }

        } else {
          this.$vs.notify({
            time: 4000,
            title: 'ATTENTION',
            text: this.$t('site_error'),
            color: 'warning',
            position: 'top-center'
          })
        }
      } else if (this.siteGroup === true) {
        if (this.produits_sites.length === 0) {
          this.produit_submit = []
          this.questions_options = [] 
          this.instability_options = []
          this.vulnerability_options = []
          this.consequence_options = []
          this.probability_options = []

          const produits_store = this.questions
          for (let i = 0; i < produits_store.length; i++) {
            this.questions_options.push(produits_store[i].id)
            this.instability_options.push(produits_store[i].instability)
            this.vulnerability_options.push(produits_store[i].vulnerability)
            this.consequence_options.push(produits_store[i].consequence)   
            this.probability_options.push(produits_store[i].probability)   
          }

          for (let i = 0; i < this.sites.length; i++) {
            const obj = {
              site_obj : this.sites[i],
              site : this.sites[i].id,
              questions: this.questions_options,
              instability_options:this.instability_options,
              vulnerability_options:this.vulnerability_options,
              consequence_options:this.consequence_options,
              probability_options:this.probability_options,   
              feasibility:this.feasibility,
              timing:this.timing,
              achievableInMoreFavorablePeriod:this.achievableInMoreFavorablePeriod,
              notFeasibleOverLongPeriod:this.notFeasibleOverLongPeriod,
              initializeCmt:this.initializeCmt
            } 
            this.produit_submit.push(obj)
          }
          this.produits_sites = this.produit_submit
          this.$vs.notify({
            color:'success',
            icon:'done',
            text:this.$t('site_add_liste'),
            position:'top-right'
          })
        } else if (this.produits_sites.length === this.sites.length) {
          this.$vs.notify({
            color:'warning',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            time:15000,
            text:this.$t('site_existe_liste'),
            position:'top-center'
          })
        }
      }
    },

    deleteProduit (site) {
      this.REMOVE_ITEM_PRODUIT(this.produits_sites, site)
      this.$vs.notify({
        color:'success',
        icon:'delete',
        text:this.$t('site_deleted_liste'),
        position:'top-right'
      })
  
    },
    addNewData () {
      this.initialise()
      this.activePrompt1 = true
    },
    ADD_ITEM_PRODUIT (produits, produit) {
      produits.unshift(produit)
    },
    FIND_ITEM_PRODUIT (produits, produit) {
      this.itemIndexProduit = produits.findIndex((p) => p.site === produit.site)
    },
    REMOVE_ITEM_PRODUIT (produits, produitId) {
      const ItemIndex = produits.findIndex((p) => p.site === produitId)
      produits.splice(ItemIndex, 1)
    },
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`securityThreatEvaluations/${id}/`)
            .then(response => {
              this.getAllsecurityThreatEvaluations()
              window.getPrendTaCom.success(this.$t('SuppressionsecurityEvaluations'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression') })
            })
        }
      })

    },

    validateFormation_intialise () {
      this.feasibility = false
      this.timing = 'immediate'
      this.achievableInMoreFavorablePeriod = false
      this.notFeasibleOverLongPeriod = false
      this.initializeCmt = false
      this.site = ''
      if (this.update_chek) {
        this.questions = []
      } else {
        this.getAllsecurityThreatQuestions()
      }
    },
    initialise () {
      this.update_chek = false
      this.obj_form = []
      this.id = ''
      this.feasibility = false
      this.timing = 'immediate'
      this.achievableInMoreFavorablePeriod = false
      this.notFeasibleOverLongPeriod = false
      this.initializeCmt = false
      this.site = ''
      this.comment = ''
      this.mission = ''
      this.siteGroup = false
      this.produits_sites = []
    },
    getAllsites (id) {
      this.$vs.loading()
      const mission = id
      this.$http.post('missionSites/', {mission})
        .then((response) => {
          this.sites = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllMissions () {
      this.$vs.loading()
      this.$http.get('missions/')
        .then((response) => {
          this.missions = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllsecurityThreatEvaluations () {
      this.$vs.loading()
      this.$http.get('securityThreatEvaluations/')
        .then((response) => {
          this.securityThreatEvaluations = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllsecurityThreatQuestions () {
      this.$vs.loading()
      this.$http.get('securityThreatQuestions/')
        .then((response) => {
          let i = 0
          this.questions = []
          const results = response.data
          results.forEach(event => {
            this.questions.push({
              id: event.id,
              name : event.name,
              instability :'negligible',
              vulnerability :'negligible',
              consequence :'negligible',
              probability : 'negligible'
            })
          })
          this.$vs.loading.close()
          this.length_questions =  i++
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  async created () {
    window.securityThreatEvaluations = this
    this.getAllsecurityThreatEvaluations()
    this.getAllMissions()
    //this.getAllsites()
  },
  mounted () {
    this.isMounted = true
    this.missionControle_data = window.Planifications.missionControle
    this.mission_data = window.Planifications.activePrompt1_EvalutionSecurity
    this.mission_data_up = window.Planifications.planification_mission_data
    this.mission_id = this.mission_data_up.id
    this.mission_name = this.mission_data_up.poNumber
    //this.getAllsecurityThreatQuestions()
    if (this.mission_data === 1) {
      this.addNewData()
    } 
    if (this.mission_data === 5) {
      this.ShowData(this.mission_data_up.missionSecurityThreatEvaluations[0])
    } 
    if (this.mission_data === 3) {
      this.editData(this.mission_data_up.missionSecurityThreatEvaluations[0])
    }  
    if (this.missionControle_data === 1) {
      this.action_button = 1
    } else if (this.missionControle_data === 2) {
      this.action_button = 2
    }

  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
